import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    pedido: {
      cliente: {},
      itens: [],
      opcaoEntregaRetirada: {},
      opcaoPagamento: "",
      observacoesPedido: "",
    },
    menuVisivel: false,
    pedidoFinalizado: {},
    categorias: [
      { id: 52, nome: "Frutas orgânicas", produtos: [] },
      { id: 33, nome: "Legumes e verduras", produtos: [] },
    ],
    opcoesPagamento: [
      { codigo: "TRANSFERENCIA", nome: "Transferência bancária" },
      { codigo: "PIX", nome: "Pix" },
      { codigo: "PICPAY", nome: "Picpay" },
    ]
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
    getCliente(state) {
      return state.pedido.cliente;
    },
    getPedido(state) {
      return state.pedido;
    },
    getPedidoFinalizado(state) {
      return state.pedidoFinalizado;
    },
    getItens(state) {
      return state.pedido.itens;
    },
    getOpcaoEntregaRetirada(state) {
      return state.pedido.opcaoEntregaRetirada;
    },
    getOpcaoPagamento(state) {
      return state.pedido.opcaoPagamento;
    },
    getObservacoesPedido(state) {
      return state.pedido.observacoesPedido;
    },
    valorTotal(state) {
      return state.pedido.itens.map(item => item.quantidade * item.precoNormal)
        .reduce((total, atual) => total + atual, 0);
    },
    quantidadeItens(state) {
      return state.pedido.itens.length;
    },
    getCategorias(state) {
      return state.categorias;
    },
    getOpcoesPagamento(state) {
      return state.opcoesPagamento;
    },
    getMenuVisivel(state) {
      return state.menuVisivel;
    }
  },
  mutations: {
    setCliente(state, payload) {
      state.pedido.cliente = payload;
    },
    setItensPedido(state, payload) {
      state.pedido.itens = payload;
    },
    setOpcaoPagamento(state, payload) {
      state.pedido.opcaoPagamento = payload;
    },
    setOpcaoEntregaRetirada(state, payload) {
      state.pedido.opcaoEntregaRetirada = payload;
    },
    setObservacoesPedido(state, payload) {
      state.pedido.observacoesPedido = payload;
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setPedidoFinalizado(state, payload) {
      state.pedidoFinalizado = payload;
    },
  },
})

