<template>
  <div class="resumo">
    <transition mode="out-in" name="slide-fade">
      <div v-show="mostrarMenu || mostrarMenuItens" class="px-2 tabela-itens">
        <item-table :itens="itens" />
      </div>
    </transition>
    <transition mode="out-in" name="slide-fade">
      <nav-buttons 
        v-if="!isHome" 
        :is-dados-cliente="isDadosCliente"
        :is-produtos="isProdutos"
      />
    </transition>
    <div class="container-fluid resumo__footer">
      <a href="#" class="text-decoration-none text-white">
        <div class="d-flex justify-content-between align-items-center py-2">
          <div class="cesta">
            <font-awesome-layers
              @click="toggleMostrarItens"
              full-width
              class="fa-3x"
            >
              <font-awesome-icon icon="shopping-basket" />
              <font-awesome-layers-text
                counter
                :value="quantidade"
                position="bottom-right"
              />
            </font-awesome-layers>
          </div>
          <button @click="toggleMostrarItens" class="mostrar-itens-carrinho font-weight-bold">
            {{ mensagemBotaoCarrinho }} itens
          </button>
          <strong @click="toggleMostrarItens" class="dinheiro-resumo">
            {{ total | dinheiro }}
          </strong>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemTable from "./components/ItemTable.vue";
import NavButtons from "./components/NavButtons.vue";

export default {
  components: {
    ItemTable,
    NavButtons,
  },
  data() {
    return {
      mostrarMenuItens: false,
    };
  },
  computed: {
    ...mapGetters({
      total: "valorTotal",
      quantidade: "quantidadeItens",
      itens: "getItens",
      mostrarMenu: "getMenuVisivel",
    }),
    mensagemBotaoCarrinho() {
      return this.mostrarMenuItens || this.mostrarMenu ? "Ocultar" : "Mostrar";
    },
    isHome() {
      return this.$route.name === "Home";
    },
    isProdutos() {
      return this.$route.name === "Produtos";
    },
    isDadosCliente() {
      return this.$route.name === "DadosCliente";
    },
  },
  methods: {
    toggleMostrarItens() {
      this.mostrarMenuItens = !this.mostrarMenuItens;
    },
  },
};
</script>
