<template>
  <main id="app">
    <topo />
    <transition mode="out-in" name="fade">
      <router-view />
    </transition>
    <footer>
      <resumo />
    </footer>
  </main>
</template>

<script>
import Topo from "@/components/Topo.vue";

export default {
  components: {
    Topo,
  },
};
</script>

<style lang="scss">
@import "./assets/styles/app";
</style>
