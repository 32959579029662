<template>
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th scope="col">Produto</th>
          <th scope="col" class="text-center">Quant.</th>
          <th scope="col" class="text-center">Total</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="itens.length">
          <tr v-for="item in itens" :key="item.id">
            <td>{{ item.nome }}</td>
            <td class="text-center">{{ item.quantidade }}</td>
            <td class="text-center">
              {{ (item.quantidade * item.precoNormal) | dinheiro }}
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="text-center">
            <td colspan="3">Sua cesta está vazia</td>
          </tr>
        </template>
      </tbody>
    </table>
  </template>
  
  <script>
  export default {
    props: {
      itens: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  