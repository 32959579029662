<template>
    <div>
      <router-link
        v-if="!isDadosCliente && isProdutos"
        :to="{ name: 'Home' }"
        class="btn btn-warning btn-block rounded-0 voltar"
        title="Continuar"
      >
        Continuar
      </router-link>
      <router-link
        v-if="!isHome && isDadosCliente"
        :to="{ name: 'Home' }"
        class="btn btn-warning btn-block rounded-0 voltar"
        title="Voltar para a Página Inicial"
      >
        Voltar para a Página Inicial
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isDadosCliente: {
        type: Boolean,
        required: true,
      },
      isProdutos: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  