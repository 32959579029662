import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/produtos/:categoria',
    name: 'Produtos',
    component: () => import(/* webpackChunkName: "produtos" */ '../views/Produtos.vue')
  },
  {
    path: '/dados-do-cliente',
    name: 'DadosCliente',
    component: () => import(/* webpackChunkName: "dados-do-cliente" */ '../views/DadosCliente.vue')
  },
  {
    path: '/finalizacao',
    name: 'Finalizacao',
    component: () => import(/* webpackChunkName: "finalizacao" */ '../views/Finalizacao.vue')
  },
  {
    path: '/pagamento',
    name: 'Pagamento',
    component: () => import(/* webpackChunkName: "finalizacao" */ '../views/Finalizacao.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
