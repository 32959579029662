import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';

import VueTheMask from 'vue-the-mask';
import Clipboard from 'v-clipboard';
import 'bootstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

import {
  faShoppingBasket,
  faSeedling,
  faCheckCircle,
  faShoppingCart,
  faTimesCircle,
  faUser,
  faMoneyCheckAlt,
  faTruck,
  faCopy
} from '@fortawesome/free-solid-svg-icons';


import router from './router';
import store from './store';
import { dinheiro, data } from './util/filters';

import Resumo from "@/components/Resumo.vue";

library.add(
  faShoppingBasket,
  faSeedling,
  faCheckCircle,
  faShoppingCart,
  faTimesCircle,
  faUser,
  faMoneyCheckAlt,
  faTruck,
  faCopy
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
Vue.component('resumo', Resumo);


Vue.filter("dinheiro", dinheiro);
Vue.filter("data", data);

Vue.use(VueTheMask);
Vue.use(Clipboard)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
